import React, { useState, useEffect } from 'react';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import styled from 'styled-components';
import { navDelay, loaderDelay } from '@utils';
import Highlight from '../highlight';

const StyledHeroSection = styled.section`
  ${({ theme }) => theme.mixins.flexCenter};
  flex-direction: column;
  align-items: flex-start;
  min-height: 100vh;
  padding: 0;

  @media (max-width: 480px) and (min-height: 700px) {
    padding-bottom: 10vh;
  }

  h1 {
    margin: 0 0 30px 4px;
    color: var(--green);
    font-family: var(--font-mono);
    font-size: clamp(var(--fz-sm), 5vw, var(--fz-md));
    font-weight: 400;

    @media (max-width: 480px) {
      margin: 0 0 20px 2px;
    }
  }

  h3 {
    margin-top: 10px;
    color: var(--light-navy);
    line-height: 0.9;
  }

  p {
    margin: 20px 0 0;
    max-width: 500px;
    color: var(--green);
  }

  .email-link {
    ${({ theme }) => theme.mixins.bigButton};
    margin-top: 50px;
    font-weight: 900;
    font-size: 14px;
    color: rgba(251, 191, 36, 1);
    border-color: rgba(251, 191, 36, 1);
  }

  .email-link:hover {
    color: white !important;
  }
`;

const Hero = () => {
  const [isMounted, setIsMounted] = useState(false);

  useEffect(() => {
    const timeout = setTimeout(() => setIsMounted(true), navDelay);
    return () => clearTimeout(timeout);
  }, []);

  const one = (
    <h1>
      Hey there{' '}
      <span role="img" aria-label="Hand Wave Emoji">
        👋
      </span>
    </h1>
  );
  const two = <h2 className="big-heading">I'm Mikel Ramos.</h2>;
  const three = <h3 className="big-heading">A frontend developer!</h3>;
  const four = (
    <>
      <p>
        I'm a Manchester-based software engineer who <Highlight>specializes</Highlight> in building
        (and occasionally designing) exceptional digital experiences.
      </p>
      <p>
        You should take a look at my <Highlight>incredible</Highlight> blog{' '}
        <span role="img" aria-label="Down Pointing Emoji">
          👇
        </span>
      </p>
    </>
  );
  const five = (
    <a href="https://blog.mikelramos.com" className="email-link">
      Check Out My Blog{' '}
      <span role="img" aria-label="Left Pointing Emoji">
        👈
      </span>
    </a>
  );

  const items = [one, two, three, four, five];

  return (
    <StyledHeroSection>
      <TransitionGroup component={null}>
        {isMounted &&
          items.map((item, i) => {
            const delay = { transitionDelay: `${i + 1}00ms` };
            return (
              <CSSTransition key={i} classNames="fadeup" timeout={loaderDelay}>
                <div style={delay}>{item}</div>
              </CSSTransition>
            );
          })}
      </TransitionGroup>
    </StyledHeroSection>
  );
};

export default Hero;
