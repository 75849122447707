import React from 'react';

const Highlight = ({ children }) => {
  return (
    <span
      style={{
        backgroundColor: 'white',
        color: 'black',
        paddingLeft: '0.25rem',
        paddingRight: '0.25rem',
        paddingTop: "0.3rem",
        paddingBottom: "0rem",
        borderRadius: "0.5rem"
      }}>
      {children}
    </span>
  );
};

export default Highlight;
